import styled from "styled-components";
import * as s from "../styles/globalStyles";
export const StyledNavBarText = styled.a`
    textAlign: center; 
    margin-left: 100px;
`;

export const Divider = styled.hr`
width: 93%;
margin: auto;
border: none;
height: 1px;
color: #f6d3ae; /* old IE */
background-color: #f6d3ae; /* Modern Browsers */


`;


function BurgerMenu({ isOpen, Setting, twitterLink, osLink, discordLink }){
    return(
        <>
            <div id="burger" className="hamburger"style={{}}>
            <StyledNavBarText style={{display: "flex", cursor: "pointer", marginLeft: "0px", padding: "7px"}} onClick={(e) => {
                var targer = document.getElementById('home_page');
                var headerOffset = 65;
                var elementPosition = targer.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                 });
                  {Setting()};
                }}>
              <s.PageTitle>
                HOME
              </s.PageTitle>
          </StyledNavBarText>
          <Divider class="rounded"></Divider>
          <StyledNavBarText style={{display: "flex", cursor: "pointer", marginLeft: "0px", padding: "7px"}} onClick={(e) => {
                
                var targer = document.getElementById('faq_page');
                var headerOffset = 65;
    var elementPosition = targer.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });

                  {Setting()};
                }}>
              <s.PageTitle>
                FAQ
              </s.PageTitle>
          </StyledNavBarText>
          <Divider class="rounded"></Divider>
          <StyledNavBarText style={{display: "flex", cursor: "pointer", marginLeft: "0px", padding: "7px"}} onClick={(e) => {
         
                  var targer = document.getElementById('team_page');
                  var headerOffset = 65;
      var elementPosition = targer.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
      window.scrollTo({
           top: offsetPosition,
           behavior: "smooth"
      });
                
                  {Setting()};
                }}>
              <s.PageTitle>
                TEAM
              </s.PageTitle>
          </StyledNavBarText>
          <Divider class="rounded"></Divider>


          <StyledNavBarText style={{textDecoration: "none", display: "flex", cursor: "pointer",  marginLeft: "0px", padding: "7px" }} href={twitterLink} target={"_blank"}>

              <s.PageTitle>
                TWITTER
              </s.PageTitle>
          </StyledNavBarText>
          <Divider class="rounded"></Divider>

          {/* <StyledNavBarText style={{textDecoration: "none", display: "flex", cursor: "pointer",  marginLeft: "0px", padding: "7px" }} href={osLink} target={"_blank"}>   
                
              <s.PageTitle>
                OPENSEA
              </s.PageTitle>
          </StyledNavBarText>
          <Divider class="rounded"></Divider> */}

          <StyledNavBarText style={{textDecoration: "none", display: "flex", cursor: "pointer",  marginLeft: "0px", padding: "7px" }} href={discordLink} target={"_blank"}>      
           
              <s.PageTitle>
                DISCORD
              </s.PageTitle>
          </StyledNavBarText>
            </div>

            <style jsx>{`
                .hamburger{
                
                    display: block;
                    opacity: ${isOpen ? '1' : '0'};
                    background-color: #333333;
                    position: fixed;
                    z-index: 3;
                    top: 65px;
                    width: 100%;
                    transition: all 0.2s;
                    transform-origin: 0% 0%;
                    
                    transform: ${isOpen ? 'scaleY(1)' : 'scaleY(0)'};
                }
              
                
            `}</style>
        </>
        
    )

}

export default BurgerMenu;
