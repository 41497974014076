import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import {useMediaQuery} from 'react-responsive';
import BurgerMenu from './components/BurgerMenu';
import ResponsiveTeamImageBig from "./components/ResponsiveTeamImageBig";
import ResponsiveTeamImageMedium from "./components/ResponsiveTeamImageMedium";
import AudioPlayer from "./components/AudioPlayer";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';


export const HomePageDesktop = ({ children }) => {
  const HomePageDesktop = useMediaQuery({ minWidth: 1001 } )
  return HomePageDesktop ? children : null
}

export const HomePagePad = ({ children }) => {
  const HomePagePad = useMediaQuery({ minWidth: 481, maxWidth: 1000 })
  return HomePagePad ? children : null
}

export const HomePageMobile = ({ children }) => {
  const HomePageMobile = useMediaQuery({ maxWidth: 480 } )
  return HomePageMobile ? children : null
}

export const HomePageDesktopAndPad = ({ children }) => {
  const HomePageMobile = useMediaQuery({ minWidth: 481 } )
  return HomePageMobile ? children : null
}


export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 715 } )
  return isMobile ? children : null
}

export const Pad = ({ children }) => {
  const isPad = useMediaQuery({ minWidth: 716, maxWidth: 1390 })
  return isPad ? children : null
}

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1391 })
  return isDesktop ? children : null
}


export const DesktopAndPad = ({ children }) => {
  const DesktopAndPad = useMediaQuery({ minWidth: 716})
  return DesktopAndPad ? children : null
}

// export const Default = ({ children }) => {
//   const isNotMobile = useMediaQuery({ minWidth: 768 })
//   return isNotMobile ? children : null
// }


export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  // width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  
`;

export const StyledDottedButton = styled.button`
font-size: 30px;
  padding: 10px;
  border: dotted;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  background-color: transparent;
  width: 150px;
  cursor: pointer;
  width: 25%;
  @media only screen and (max-width: 1024px) {
    font-size: 30px
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: orange;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const StyledLogo = styled.img`
  width: 55px;
  top: 4px;
  left: 15px;
  cursor: pointer;
  
  @media only screen and (max-width: 600px) {
    width: 55px;
    left: 5%;
  }

  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledNavBar = styled.img`
  width: 25px;
  transition: width 0.5s;
  transition: height 0.5s;
`;


export const StyledNavBarText = styled.span`
  textAlign: center; 


  margin-left: 66px;
`;

export const ConnectButton = styled.img`
  width: 150px;
  transition: width 0.5s;
  transition: height 0.5s;
`;


export const HeaderImg = styled.img`
  transition: width 0.5s;
  width: 22%;
  @media only screen and (max-width: 600px) {
    width: 35%;
  }
`;


export const FaqImg = styled.img`
  transition: width 0.5s;
  width: ${({ wd }) => (wd ? wd : "100%")};
  @media (max-width: 600px) {
      width: 100%;
  }
  @media (min-width: 600px) and (max-width: 1390px) {
    width: 100%;
  }
`;

export const TeamImg = styled.img`
  transition: width 0.5s;
  width: ${({ wd }) => (wd ? wd : "100%")};
  @media (max-width: 900px) {
      width: 100%;
  }
`;

export const TeamImgIntro = styled.img`
  transition: width 0.5s;
  width: ${({ wd }) => (wd ? wd : "100%")};
  @media (max-width: 900px) {
      width: 90%;
      padding-top:15px;
      margin: auto;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
`;

export const Header = styled.div`
  top: 0;
  display: flex;
  position: relative;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SinglePage = styled.div`
  display: block;
  max-width: 1500px;
`;

export const TextContainer = styled.a`
  display: block;
  text-align: center;CON
`;

export const Divider = styled.hr`
  width: 100%;
  display: hide;
  /* color: green; */
  border-top: 6px solid #8dbe3a;
`;

export const ColorText = styled.span`
  color: #FF9F40; 
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click "DEAL" to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [faq, setFaq] = useState([true, false, false, false, false]);
  const [test, setTest] = useState(false);
  const [accordionState, setAccordionState] = useState([]);

  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "Sento",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: ""
  });


  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(Math.floor(gasLimit * Math.pow(1.1, mintAmount)));
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Dealing your hands...`);
    setClaimingNft(true);
    
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        if(err.code == 4001) {
          setFeedback("User denided the transaction");
        } else {
          setFeedback("Sorry, something went wrong, please try again. If problem continues, please DM on Twitter.");
        }
        setClaimingNft(false);
      })
      .then((receipt) => {
          console.log(receipt);
          if (mintAmount > 1) {
              setFeedback(
                  `Congratulations! You were dealt ${mintAmount} ${CONFIG.NFT_NAME} NFTs! Visit Opensea.io/profile to view your hands.`
              );
          } else {
              setFeedback(
                  `Congratulations! You were dealt ${mintAmount} ${CONFIG.NFT_NAME} NFT! Visit Opensea.io/profile to view your hand.`
              );}
          
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  const toggleHamburger = () =>{
    setHamburgerOpen(!hamburgerOpen)
  }

  
  document.addEventListener( "click", (e) => {
  if(e.target.id !== "burger" && e.target.id !== "burger-1") {
    if(hamburgerOpen) {
      toggleHamburger()
    }
  }
  });

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const goHomePage = () =>{
    var targer = document.getElementById('home_page');
    var headerOffset = 65;
    var elementPosition = targer.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{backgroundColor: "#6e6054", position: "relative"}}>
        <AudioPlayer> </AudioPlayer>
        <Header style={{position: "fixed", backgroundColor: "#ea5e57", width: "100%", height: "65px", zIndex: "3"}}>
          <Mobile>
            <div style={{position: "absolute", top: "4px"}}>
              <StyledLogo  id="burger-1" style={{margin:"5px 5px 10px 8px", width: "32px"}} alt={"logo"} src={"/config/images/mobile/menu_icon.png"} onClick={toggleHamburger}/>
              <StyledLogo  style={{marginLeft:"3px"}}alt={"logo"} src={"/config/images/logo.png"}  onClick={(e) => {goHomePage();}}/>
            </div>
          </Mobile>
          <DesktopAndPad>
            <StyledLogo style={{position: "absolute"}} alt={"logo"} src={"/config/images/logo.png"} onClick={(e) => {goHomePage();}}/>
            <HeaderWrapper style={{marginLeft: "50px"}}>
              <StyledNavBarText style={{display: "flex", cursor: "pointer" }} onClick={(e) => {goHomePage();}}>
                <s.PageTitle>
                  HOME
                </s.PageTitle>
              </StyledNavBarText>
              <StyledNavBarText style={{ padding: "10px 0px" , display: "flex", cursor: "pointer" }}    onClick={(e) => {
                    document.getElementById('faq_page').scrollIntoView({alignToTop: true, behavior: "smooth"});
                }}>
                <s.PageTitle>
                  FAQ
                </s.PageTitle>
              </StyledNavBarText>
              <StyledNavBarText style={{display: "flex", cursor: "pointer" }}    onClick={(e) => {
                  document.getElementById('team_page').scrollIntoView({alignToTop: true, behavior: "smooth"});
                }}>
                <s.PageTitle>
                  TEAM
                </s.PageTitle>
              </StyledNavBarText>
              <StyledNavBarText style={{display: "flex", cursor: "pointer" }}>
                <a style={{ textDecoration: "none"}} href={CONFIG.DISCORD_LINK} target={"_blank"}>
                  <s.PageTitle>
                    DISCORD
                  </s.PageTitle>
                </a>
              </StyledNavBarText>
            </HeaderWrapper>
          </DesktopAndPad>
    
          <HeaderWrapper style={{ position: "absolute", right: "5px", top: "13px"}}>
            {/* <a style={{ size: "25px", padding: "10px 10px"}} href={CONFIG.MARKETPLACE_LINK} target={"_blank"}>
              <StyledNavBar alt={"logo"} src={"/config/images/oslogo.png"} />
            </a> */}
            <a style={{ textDecoration: "none",padding: "10px 10px"}} href={CONFIG.TWITTER_LINK} target={"_blank"}>
              <StyledNavBar alt={"logo"} src={"/config/images/twitter.png"} />
            </a>
            <a style={{paddingRight: "16px", paddingLeft: "10px"}} href={CONFIG.DISCORD_LINK} target={"_blank"}>
              <StyledNavBar alt={"logo"} src={"/config/images/discord.png"} />
            </a>
          </HeaderWrapper>  
        </Header>
        <BurgerMenu isOpen={hamburgerOpen} 
          Setting={toggleHamburger} 
          twitterLink={CONFIG.TWITTER_LINK}
          osLink = {CONFIG.MARKETPLACE_LINK}
          discordLink = {CONFIG.DISCORD_LINK}/>
        <s.Container id="home_page" style={{position: "relative", top: "65px"}} flex={1} ai={"center"} >
          <HomePagePad>
            <div style={{position: "relative"}}>
              <video width="100%" autoPlay={true} muted playsInline={true} loop={true}>
                <source src="https://sento-resources.s3.us-west-2.amazonaws.com/padhome-2.mp4" type="video/mp4"/>
              </video>
              <div style={{ 
                    position: "absolute", 
                    left: "0", 
                    right: "0",
                    margin: "auto",
                    top: "71%"}}>   
                <s.TextTitle>
                  SENTO is a NFT Collection of 3,333 bathing penguins,  inspired by <br></br> 
                  Japanese traditional public bath culture. To all exhauseted souls, <br></br> 
                  drowsy eyes, burdened bodies, or simply fans of hot baths.  <br></br>
                  Welcome to SENTO, 
                  a space for you to get naked, rinse it off, and just relax!
                </s.TextTitle>
              </div> 
            </div> 
          </HomePagePad>
          <HomePageDesktop>
            <div style={{position: "relative"}}>
              <video width="100%" autoPlay={true} muted playsInline={true} loop={true}>
                <source src="https://sento-resources.s3.us-west-2.amazonaws.com/homepageanimation.mp4" type="video/mp4"/>
              </video>
              <div style={{ 
                  position: "absolute", 
                  left: "0",
                  right: "0",
                  margin: "auto",
                  top: "71%"}}>   
              <s.TextTitle>
                SENTO is a NFT Collection of 3,333 bathing penguins, <br></br> inspired by Japanese traditional public bath culture.<br></br>
                To all exhauseted souls, drowsy eyes, burdened bodies, or simply fans of hot baths <br></br>
                Welcome to SENTO, a space for you to get naked, rinse it off, and just relax!
              </s.TextTitle>
            </div> 
            </div>
          </HomePageDesktop>
          <HomePageMobile>
            <div style={{position: "relative"}}>
              <video width="100%" autoPlay={true} muted playsInline={true} loop={true}>
                <source src="https://sento-resources.s3.us-west-2.amazonaws.com/mobilehome.mp4" type="video/mp4"/>
              </video>
              <div style={{position: "absolute", left: "0", right: "0",
                margin: "auto",
                bottom: "6%"}}>   
                <s.TextTitle>
                  SENTO is a NFT Collection of <br></br>3,333 bathing penguins, inspired by <br></br>Japanese traditional public bath culture.
                </s.TextTitle>
                <s.TextTitle>
                To all exhauseted souls, <br></br> drowsy eyes, burdened bodies, <br></br> or simply fans of hot baths
              </s.TextTitle>
              <s.TextTitle>
                Welcome to SENTO, a space for you to get <br></br>naked, rinse it off, and just relax!
              </s.TextTitle>
            </div> 
          </div>
        </HomePageMobile>
            {/* <s.SpacerLarge />
            <s.SpacerLarge />
            <s.SpacerLarge />
            <s.SpacerLarge /> */}
            {/* <s.BigButton
              style={{
              textAlign: "center",
              color: "var(--primary-text)",
              border: "dotted",
              padding: "7px",
              display: "inline"}}> 
              Dealing soon ...   <img style={{width: "20%"}} alt={"logo"} src={"/config/images/joker.png"}></img>
            </s.BigButton> */}      
            {/* Uncomment when it's ready */}
            {/* <StyledDottedButton
                onClick={(e) => {
                  document.getElementById('mint_page').scrollIntoView({behavior: "smooth"});
                }}
                style={{
                  margin: "5px",
                }}
              >     
              Mint
            </StyledDottedButton> */}
        </s.Container> 
        <s.Container  jc={"center"} ai={"center"} image={"/config/images/bg.jpg"} imageM={"/config/images/pad/bg.jpg"} imageS={"/config/images/mobile/bg.jpg"} style={{position: "relative", top: "65px"}}> 
          <div id="wrap">
            <div id="viewport">
            </div>
          </div> 
          <s.Container  id="faq_page"  jc={"center"} ai={"center"} width={"65%"} widthS={"89%"} style={{paddingTop: "8%"}}> 
            <HeaderImg alt={"example"} src={"/config/images/FAQ-tag.png"}></HeaderImg>
            <s.FAQContainer jc={"center"} ai={"center"} fd={"row"} fds={"column"} 
              width={"100%"} widthM = {"70%"} width_full= {"100%"} width_mid = {"50%"} style={{paddingTop: "10%"}}> 
              <div style={{position: "relative"}}>
                <Mobile>
                  <img src="/config/images/mobile/FAQ-1.png" alt="Snow" style={{width:"100%"}}></img>
                </Mobile>
                <Pad>
                  <img src="/config/images/pad/FAQ-1.png" alt="Snow" style={{width:"100%"}}></img>
                </Pad>
                <Desktop>
                  <img src="/config/images/FAQ-1.png" alt="Snow" style={{width:"100%"}}></img>
                </Desktop>
                <div style={{position: "absolute", top: "7%", left: "2%"}}>
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What is SENTO?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          SENTO is a NFT collection of 3,333 bathing chill-penguins generated from over 100 Japanese Sento- and anime culture-inspired traits. They are stored as ERC-721 tokens on the Ethereum blockchain. 
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What does sento originally mean?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Sentō (銭湯) is a type of traditional Japanese communal bathhouse, first seen in Kyoto, Japan during the Heian period (794-1185). Customers pay a modest fee for entrance, and many enjoy Sento as a place of relaxation, community, and chill.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Utility and Roadmap?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We are focused on building a chill community where members can relax, have a sip of cold milk, and share their love for hot baths. No buzzwords, no empty promises. Let’s put those NFT analysis aside, get naked and step into the tub! 
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          How do I get MY SENTOs?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          You can mint SENTO NFTs during pre-mint and/or public mint. <Mobile><br></br></Mobile>Join our discord for more details! (Invitation only, opening to public soon)
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Mint date & Price?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We are targeting a mint date in June. Details will be announced as soon as we’re ready. Pay close attention to announcements on the official twitter and discord!
                          <ColorText> PLEASE BE SURE YOU ARE ONLY MINTING THROUGH OUR OFFICIAL WEBSITE!</ColorText>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div> 
              <s.SpacerMedium />
              <s.SpacerMedium />
              <div style={{position: "relative"}}>
                <Mobile>
                  <FaqImg alt={"example"} src={"/config/images/mobile/FAQ-2.png"}></FaqImg>
                </Mobile>
                <Pad>
                  <FaqImg alt={"example"} src={"/config/images/pad/FAQ-2.png"}></FaqImg>
                </Pad>
                <Desktop>
                  <FaqImg alt={"example"} src={"/config/images/FAQ-2.png"}></FaqImg>
                </Desktop>
              </div>
            </s.FAQContainer>
            <HeaderImg  id="team_page" alt={"example"} src={"/config/images/TEAM-tag.png"} style={{paddingTop: "10%"}}></HeaderImg>
            <Pad>
              <div alt={"example"} style={{width: "90%", paddingTop: "6%"}}>
              <ResponsiveTeamImageMedium></ResponsiveTeamImageMedium>
              </div>
            </Pad>
            <Desktop>
              <div style={{width: "auto", height: "auto", paddingTop: "6%"}}>
              <ResponsiveTeamImageBig></ResponsiveTeamImageBig>
              </div>
            </Desktop>
            <Mobile>
              <div class="wrapper">
                <a href="https://twitter.com/koyadouji">
                  <div class="one" style={{display: "grid"}}>
                    <TeamImg alt={"example"} src={"/config/images/mobile/team1.png"}></TeamImg>
                    <TeamImgIntro alt={"example"} src={"/config/images/mobile/team1_tag.png"} ></TeamImgIntro>
                  </div>
                </a>
                <div class="two" style={{display: "grid"}}>
                  <TeamImg alt={"example"} src={"/config/images/mobile/team2.png"} ></TeamImg>
                  <TeamImgIntro alt={"example"} src={"/config/images/mobile/team2_tag.png"} ></TeamImgIntro>
                </div>
                <div class="three" style={{display: "grid"}}>
                  <TeamImg alt={"example"} src={"/config/images/mobile/team3.png"} ></TeamImg>
                  <TeamImgIntro alt={"example"} src={"/config/images/mobile/team3_tag.png"} ></TeamImgIntro>
                </div>
                <a href="https://twitter.com/GG00DB0Y">
                  <div  class="four" style={{display: "grid"}}>
                    <TeamImg alt={"example"} src={"/config/images/mobile/team4.png"} ></TeamImg>
                    <TeamImgIntro alt={"example"} src={"/config/images/mobile/team4_tag.png"} ></TeamImgIntro>
                  </div>
                </a> 
              </div> 
            </Mobile>
            <s.SpacerLarge />
            <s.SpacerLarge />
          </s.Container>
        </s.Container>
        {/* Uncomment when it's ready */}      

        {/*          
        <Divider id="mint_page" class="rounded"></Divider>    */}
        
        {/* <SinglePage> 
          <s.Container jc={"center"} ai={"center"} width={"70%"}> 
            <s.PageTitle>
              MINT
            </s.PageTitle>  
            <s.SpacerLarge/>
            <s.Container
              flex={2}
              jc={"center"}
              ai={"center"}
              style={{
                backgroundColor: "var(--accent)",
                padding: 24,
                borderRadius: 24,
                border: "4px dashed var(--secondary)",
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                width: "100%"
              }}
            >
            <s.TextTitle
              style={{
                textAlign: "center",
                // fontSize: 45,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}>
              {data.totalSupply == undefined? ("?") : (data.totalSupply)} / {CONFIG.MAX_SUPPLY} tokens have been minted! 
            </s.TextTitle>
            <s.SpacerSmall />

            <span style={{ display: "flex"}}>
              <s.TextDescription style={{color: "grey", marginRight: "5px"}}>  
                Contract:   
              </s.TextDescription>
              <a href={CONFIG.SCAN_LINK} target="_blank" >
                <s.TextDescriptionCut style={{ color: "grey", textDecoration:"underline"}}> {CONFIG.CONTRACT_ADDRESS}  </s.TextDescriptionCut>
              </a>
            </span>
            <s.SpacerMedium />
            <span style={{ display: "flex"}}>
              <s.TextDescription
                  style={{ textAlign: "center", color: "grey", margin: "auto" }}>
                Your wallet
              </s.TextDescription>
              <s.SpacerXSmall />
              {blockchain.account === "" || blockchain.account === null ? (   
                <StyledButton
                  onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                  }}
                >
                  CONNECT 
                </StyledButton>
              ): (
                <s.TextDescriptionCut style={{ color: "grey"}}>
                  {blockchain.account}
                </s.TextDescriptionCut>
            )}
            </span>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle>
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                > 
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle>
                  {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL} per NFT
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "grey" }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                
                <s.SpacerSmall />
                {blockchain.account === "" || blockchain.account === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    
                    <s.TextDescription
                      style={{
                        textAlign: "center"
                      }}
                    >
                      Please connect to {CONFIG.NETWORK.NAME} network.
                    </s.TextDescription>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center"
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerSmall/>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        fontSize: "11px"
                      }}
                    >
                      Max 5 mints per transaction; max 20 mints per wallet
                    </s.TextDescription>
                    <s.SpacerSmall/>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "Dealing..." : "DEAL"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
                <s.SpacerLarge />
                <s.SpacerLarge />
                <TextContainer style={{ width: "100%" }}>
                  <s.TextDescription
                    style={{
                    textAlign: "center",
                    color: "grey",
                    }}
                  >
                    Please make sure you are connected to the right network (
                    {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
                    Once you make the purchase, you cannot undo this action. 
                  </s.TextDescription> 
                  <s.SpacerSmall />
                  <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "grey",
                      }}
                  >
                    We have set the gas limit to 110,000 for 1 mint to ensure successful mint. 
                    We recommend that you don't lower the gas limit.
                  
                  </s.TextDescription>
                </TextContainer>
              </>
              )}
              <s.SpacerMedium />
            </s.Container>
          </s.Container>
        </SinglePage>
        <s.SpacerMedium/> */}
      {/* <s.Container  id="faq_page"  jc={"center"} ai={"center"} width={"65%"} widthS={"89%"} style={{paddingTop: "8%"}}>  */}
      <Footer style={{display: "flex", height: "150px", width: "100%", position: "relative"}}>
        <s.TextSubTitle style={{
                textAlign: "center",
                margin: "auto",
                marginTop: "80px",
              }}>
            Copyright &copy; 2022 SENTO.
        </s.TextSubTitle>
      </Footer>
    </s.Container>
  </s.Screen>
);}

export default App;
