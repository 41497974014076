import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

`;


// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: ${({ width }) => (width ? width : "100%" )};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  @media only screen and (max-width: 1390px) and (min-width: 716px) {
    width: ${({ widthS }) => (widthS ? widthS : "100%" )};
    flex-direction: ${({ fds }) => (fds ? fds : "column" )};
    background-size: cover;
    background-image: ${({ imageM }) => (imageM ? `url(${imageM})` : "none")};
   
  }
  @media only screen and (max-width: 715px) {
    width: ${({ widthS }) => (widthS ? widthS : "100%" )};
    flex-direction: ${({ fds }) => (fds ? fds : "column" )};
    background-size: cover;
    background-image: ${({ imageS }) => (imageS ? `url(${imageS})` : "none")};
   
  }
  max-width: 1920px;
`;

// Used for providing a wrapper around a component
export const FAQContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: ${({ width }) => (width ? width : "100%" )};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: contain;

  background-repeat: no-repeat;
  margin: auto;
 
  @media only screen and (max-width: 1390px) and (min-width: 716px){
    width: ${({ widthM }) => (widthM ? widthM : "70%" )};
    flex-direction: ${({ fds }) => (fds ? fds : "column" )};
    background-size: cover;
    background-image: ${({ imageS }) => (imageS ? `url(${imageS})` : "none")};
   
  }

  @media only screen and (max-width: 716px) {
    width: ${({ widthS }) => (widthS ? widthS : "100%" )};
    flex-direction: ${({ fds }) => (fds ? fds : "column" )};
    background-size: cover;
    background-image: ${({ imageS }) => (imageS ? `url(${imageS})` : "none")};
   
  }
  max-width: 1920px;
`;

export const PageTitle = styled.p`
  color: var(--primary-text);
  font-size: 20px;
  line-height: 1.6;
  font-family: myFirstFont;
  text-align: center;
  margin: auto;
  
  &:hover {
    color: #333333;
  }

  @media only screen and (max-width: 715px) {
    &:hover {
      color: #f56463;
    }
  }
 
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 20px;
  font-weight: bold;
  line-height: 2.3;
  text-align: center;
  margin: auto;
  font-family: montserrat;
  @media only screen and (max-width: 1390px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 1390px) and (min-width: 716px){
  }

  @media only screen and (max-width: 716px) {
    font-weight: 500;
    font-size: 15px;
    line-height: 2.1;
  
  }
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
  font-family: montserrat;
  @media only screen and (max-width: 1390px) {
    font-size: 16px;
  }
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
  font-family: montserrat;
  @media only screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const TextDescriptionCut = styled.p`
font-family: montserrat;  
color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 1024px) {
    font-size: 14px;
    width: 180px;
  }
`;


export const BigButton = styled.p`
  color: var(--primary-text);
  font-size: 60px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  width: 69%;
  @media only screen and (max-width: 1024px) {
    font-size: 22px;
    width: 58%
  }
`;