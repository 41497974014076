import React from "react";
import { ImageMap } from "@qiuz/react-image-map";


// See instruction at
// https://www.npmjs.com/package/@qiuz/react-image-map


const onMapClick = (area, index) => {
};

const mapArea: any[] = [
 
  {
    // the position of the left most penguin
    "width":"16.212534059945504%",
    "height":"56.82539682539682%",
    "left":"19.537636239782017%",
    "top":"0%",
    "render": (area, index) => (
      <a
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
        href="https://twitter.com/koyadouji"
        target="_blank"
      ></a>
    )       
  },{   
    // the position of the right most penguin
    "width":"17.302452316076295%",
    "height":"54.920634920634924%",
    "left":"68.44771798365123%",
    "top":"0%",
    "render": (area, index) => (
      <a
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
        href="https://twitter.com/GG00DB0Y"
        target="_blank"
      ></a>
  )}
];

export default function ResponsiveTeamImageBig() {
  const img = "/config/images/team.png";

  const ImageMapComponent = React.useMemo(
    () => (
      <ImageMap
        className="usage-map"
        src={img}
        map={mapArea}
        onMapClick={onMapClick}
      />
    ),
    [img]
  );

  return <div>{ImageMapComponent}</div>;
}
