import React, {useState} from "react";
import styled from "styled-components";

export const StyledNavBar = styled.img`
    max-width: 100%;
    width:73px;
    transition: width 0.5s;
    transition: height 0.5s;
`;

export const AudioIcon = styled.div` 
    position: absolute;
    top: 90vh;
    width: 73px;
    height: auto;
    z-index: 5;
    right: 0px;
    cursor: pointer;
    @media only screen and (max-width: 716px) {
        width: 50px;
        top: 70vh;
    }
`;



const audio = new Audio(
    "https://sento-resources.s3.us-west-2.amazonaws.com/sento-music.mp3"
);
audio.loop = true;


export default function AudioPlayer() {
    const [audioOn, setAudioOn] = useState(false);
    
    const start = (currentAudio) => {
        currentAudio.play();
        setAudioOn(!audioOn);
    };

    const stop = (currentAudio) => {
        currentAudio.pause();
        setAudioOn(!audioOn);
    };

    return (
        <AudioIcon>
            <div style={{position:"fixed"}}>
                {audioOn == true ? (
                    <StyledNavBar alt={"logo"} src={"/config/images/bgm-on.png"} display="none" onClick={(e) => {stop(audio);}}/>
                ): (
                    <StyledNavBar alt={"logo"} src={"/config/images/bgm-off.png"} onClick={(e) => {start(audio);}}/>
                )}
            </div>
        </AudioIcon>
    );
}