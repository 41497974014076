import React from "react";
import { ImageMap } from "@qiuz/react-image-map";

// See instruction at
// https://www.npmjs.com/package/@qiuz/react-image-map


const onMapClick = (area, index) => {
};

const mapArea: any[] = [
    {
        "width":"20.057220708446867%",
        "height":"56.871794871794876%",
        "left":"9.673024523160763%",
        "top":"0%",
        "render": (area, index) => (
            <a
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                
                height: "100%",
              }}
              href="https://twitter.com/koyadouji"
              target="_blank"
            >
             
            </a>
          ),
        
    },
    {   "width":"19.34604904632152%",
    "height":"54.871794871794876%",
    "left":"71.3087534059946%",
    "top":"0%",
  "render": (area, index) => (
    <a
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        
        height: "100%",
      }}
      href="https://twitter.com/GG00DB0Y"
      target="_blank"
    >
     
    </a>
  )}
];

// in hooks
export default function ResponsiveTeamImageMedium() {
  const img = "/config/images/pad/3_penguinns.png";
  const ImageMapComponent = React.useMemo(
    () => (
      <ImageMap
        className="usage-map"
        src={img}
        map={mapArea}
        onMapClick={onMapClick}
      />
    ),
    [img]
  );

  return <div>{ImageMapComponent}</div>;
}
